<template>
  <header style="z-index: 1000">
    <nav
      class="navbar navbar-expand-md navbar-liggth"
      :class="{ green: $route.path != '/' }"
      style="background: #0b2b33"
    >
      <div class="container">
        <router-link class="navbar-brand p-0" to="/">
          <img src="@/assets/Logogeoloctel.png" alt="" class="logo" />
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fa-solid fa-bars" style="color: white"></i>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <div class="navbar-nav">
            <router-link class="nav-link" to="/">Home page</router-link>
            <router-link class="nav-link" to="/pricing">Rates</router-link>
            <router-link class="nav-link" to="/faq">F.A.Q.</router-link>
            <router-link class="nav-link" to="/contact">Contact</router-link>

            <span
              class="btn nav-link"
              type="button"
              @click="$emit('changeForm', { idForm: 1, titleForm: 'Log in' })"
              v-if="!this.$store.getters['Auth/getLogin']"
              >Log in</span
            >

            <div class="dropdown nav-link px-4 position-relative" v-else>
              <a
                href="#"
                class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="@/assets/user.png"
                  alt=""
                  width="32"
                  height="32"
                  class="rounded-circle me-2"
                />
              </a>
              <ul
                class="dropdown-menu text-small shadow"
                aria-labelledby="dropdownUser1"
                style="position: absolute; left: -70px; background: white"
              >
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/Profile"
                    style="color: black"
                    >Profile</router-link
                  >
                </li>
                <li><a class="dropdown-item" @click="sendLogout">Out</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container col-1"
        style="display: flex; justify-content: space-evenly"
      ></div>
    </nav>
  </header>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("Auth", ["logout"]),
    async sendLogout() {
      this.$emit("loading", { active: true });
      await this.logout();
      const that = this;
      setTimeout(() => {
        that.$emit("loading", { active: false });

        that.$router.push("/");
      }, 2000);
    },
  },
  components: {},
};
</script>

<style lang="scss">
header {
  position: fixed;
  z-index: 10;
  width: 100%;
  .navbar {
    flex-direction: column;
    align-content: stretch;
    justify-content: center !important;
    .navbar-nav {
      display: inline-block;
    }
    .navbar-brand {
      .logo-img {
        width: 153px;
        @media screen and (min-width: 990px) {
          width: 190px;
        }
      }
    }
    .nav-link {
      white-space: nowrap;
      text-align: left;
      font-style: normal;
      margin-left: 12px;
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      width: auto;
      font-size: 16px;
      padding: 0;
      @media (min-width: 992px) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    .btn {
      background: white !important;
      color: #0b2b33 !important;
      cursor: pointer;
      white-space: nowrap;
      text-align: left;
      font-family: Poppins SemiBold;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 990px) {
    #navbarNavAltMarkup {
      background: #0b2b33;

      .navbar-nav {
        display: flex;
        .nav-link {
          display: block;
          height: 50px;
        }
        .btn {
          width: 50%;
          text-align: center;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    #navbarNavAltMarkup {
      margin-top: 100px;
    }
    .dropdown-menu {
      left: 70% !important;
    }
  }
  @media screen and (max-width: 576px) {
    .dropdown-menu {
      left: 65% !important;
    }
  }
}
.dropdown {
  @media screen and (max-width: 768px) {
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
  }
}

.navbar-collapse {
  justify-content: space-between;
}
.logo {
  max-width: 200px;
}
</style>
