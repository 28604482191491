import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export default {
  namespaced: true,
  state: {
    idToken: null,
    expiresIn: null,
    localId: null,
    refreshToken: null,
    login: false,
    refreshInterval: null,
  },
  getters,
  mutations,
  actions,
};
