import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import HomeView from "../views/HomeView.vue";
import pricing from "../views/PricingView.vue";

const routes = [
  {
    path: "/test",
    name: "test",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/TestView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: pricing,
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () =>
      import(/* webpackChunkName: "cookies" */ "../views/CookiesView.vue"),
  },
  {
    path: "/conditions",
    name: "conditions",
    component: () =>
      import(/* webpackChunkName: "term" */ "../views/TerConView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/PFView.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () =>
      import(/* webpackChunkName: "reset" */ "../views/ResetView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
    beforeEnter: () => {
      if (!Cookies.get("auth")) return { name: "home" };
      let cookies = JSON.parse(Cookies.get("auth"));

      if (cookies.login) return true;

      return { name: "login" };
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "map" */ "../views/MapView.vue"),
    beforeEnter: (to) => {
      if (!Cookies.get("auth")) return { name: "home" };
      let cookies = JSON.parse(Cookies.get("auth"));

      if (!to.query.latitude) {
        return { name: "home" };
      }
      if (cookies.login) return true;
      return { name: "login" };
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
