<template>
  <div class="contact-banner">
    <div class="container">
      <div class="entries row justify-content-center">
        <div
          class="entry__content col d-flex justify-content-end align-items-center"
        >
          <img
            src="https://geomy.mobi/img/mail.png"
            width="24"
            height="24"
            alt=""
          />
          <a href="mailto:info@geoloctel.com" class="highlight"
            >info@geoloctel.com</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.contact-banner {
  text-align: left;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 60px;
  background-color: #fff;
  font-family: Poppins Regular, sans-serif;
  .entries {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    @media (min-width: 992px) {
      -webkit-box-pack: end;
      justify-content: flex-end;
      .entry__phone {
        padding-left: 25px;
      }
    }
    .entry {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
    }
    .entry__mail {
      display: none;
      @media (min-width: 992px) {
        display: -webkit-box;
        display: flex;
        padding-right: 25px;
      }
    }
    .entry__icon {
      flex-basis: 24px;
      img {
        opacity: 0.5;
      }
    }
    .entry__content {
      -webkit-box-flex: 1;
      flex: 1;
      padding-left: 15px;
    }
  }
  .highlight {
    font-size: 18px;
    line-height: 20px;
    color: #e9381b;
    margin-left: 10px;
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
  .sub {
    font-size: 13px;
    line-height: 13px;
    color: #323232;
    @media screen and (max-width: 400px) {
      font-size: 10px;
    }
  }
}
</style>
