import Cookies from "js-cookie";
export const mutations = {
  UpdateAuth: async (state, payload) => {
    if (payload.login) {
      var cookies = {};
      state.login = payload.login;
      if (Cookies.get("auth")) {
        cookies = JSON.parse(Cookies.get("auth"));
      }

      cookies.login = payload.login;
      if (payload.idToken) {
        state.idToken = payload.idToken;
        cookies.idToken = payload.idToken;
      }
      if (payload.expiresIn) {
        (state.expiresIn = payload.expiresIn),
          (cookies.expiresIn = payload.expiresIn);
      }
      if (payload.localId) {
        (state.localId = payload.localId), (cookies.localId = payload.localId);
      }
      if (payload.refreshToken) {
        (state.refreshToken = payload.refreshToken),
          (cookies.refreshToken = payload.refreshToken);
      }
      if (payload.refreshInterval) {
        (state.refreshInterval = payload.refreshInterval),
          (cookies.refreshInterval = payload.refreshInterval);
      }

      Cookies.set("auth", JSON.stringify(cookies));
    }
    //-------
  },
  CleanAuth: (state) => {
    if (Cookies.get("auth")) {
      Cookies.remove("auth");
    }
    state.idToken = null;
    state.expiresIn = null;
    state.localId = null;
    state.refreshToken = null;
    state.login = false;
    state.refreshInterval = null;
  },
  RefreshInterval(state, payload) {
    state.refreshInterval = payload;
  },

  test: () => {},
};
