<template>
  <div class="form-container">
    <form>
      <h2>
        Locate a <br />
        cell phone <br />
        anywhere.
      </h2>
      <br />
      <label for="intl-tel-landing" class="subtitle"
        >Enter the number you want to locate :</label
      >
      <br />
      <br />
      <div class="localize-form">
        <vue-tel-input
          ref="phone"
          :inputOptions="options"
          :disabled="disabled"
          :onInput="oninput"
          defaultCountry="US"
          :autofocus="true"
          :dynamicPlaceholder="true"
          mode="international"
          autocomplete="on"
          :disabledFetchingCountry="true"
          placeholder="Phone Number..."
        ></vue-tel-input>
      </div>
      <br /><br />
      <div class="email-form">
        <label for="account-email" class="subtitle"
          >Your email address to be informed of the result :</label
        >

        <div id="account-email-wrapper" class="input-wrapper mt-3">
          <i class="fa fa-envelope font-awesome-position"></i>
          <input
            id="account-email"
            type="email"
            placeholder="Email"
            v-model="state.email"
          />
          <div
            class="input-errors"
            v-for="error of v$.email.$errors"
            :key="error.$uid"
          >
            <small class="error-msg">{{ error.$message }}</small>
          </div>
        </div>

        <button
          class="btn localize-guest localize-trigger mt-3"
          @click.prevent="send"
        >
          <i class="fa fa-location-arrow"></i>
          Start localization
        </button>
        <br />
        <br />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { mapActions } from "vuex";

export default {
  name: "gForm1",
  components: {},
  setup() {
    const state = reactive({
      email: "",
    });
    const rules = computed(() => {
      return {
        email: {
          required: helpers.withMessage("field required", required),
          email: helpers.withMessage("Email invalid", email),
        },
      };
    });
    const v$ = useVuelidate(rules, state);
    return {
      state,
      v$,
      options: { placeholder: "Phone Number..." },
    };
  },
  methods: {
    ...mapActions("User", ["sendSms"]),
    async send() {
      try {
        const phone = this.$refs.phone.phoneObject;

        if (await this.v$.$validate()) {
          if (!phone.country) {
            this.$notify({
              group: "basic",
              speed: 1000,
              title: "OOPS!",
              text: "Select Country Code.",
              type: "error",
              duration: 3000,
            });

            return;
          }
          if (!phone.valid) {
            this.$notify({
              group: "basic",
              speed: 1000,
              title: "OOPS!",
              text: "Invalid Number.",
              type: "error",
              duration: 3000,
            });

            return;
          }

          if (!this.$store.getters["Auth/getLogin"]) {
            this.$root.formChange({
              idForm: 2,
              titleForm: "Create an account",
            });
            return;
          }

          let payload = {
            Email: this.state.email,
            Phone: phone.number.replace("+", ""),
            Phone_code: phone.countryCode.toLowerCase(),
            Type: 2,
            cost: 2,
          };
          const remaining =
            this.$store.getters["User/getCredits"] -
            this.$store.getters["User/getCreditsSpent"];

          if (remaining < 2) {
            this.$notify({
              group: "basic",
              speed: 1000,
              title: "OOPS!",
              text: "Iinsufficient Credit",
              type: "error",
              duration: 3000,
            });
            return;
          }
          this.$emit("loading", { active: true });
          await this.sendSms(payload);
          this.$notify({
            group: "basic",
            speed: 1000,
            title: "Perfect!",
            text: "The geolocation link has been sent to your device.",
            type: "success",
            duration: 3000,
          });
          this.$emit("loading", { active: false });
          this.cleanForm();
          this.$router.push("/profile");
        }
      } catch (error) {
        if (error) {
          this.cleanForm();
          this.$emit("loading", { active: false });
          this.$notify({
            group: "basic",
            speed: 1000,
            title: "OOPS!",
            text: "an error occurred while sending the message",
            type: "error",
            duration: 3000,
          });
        }
      }
    },
    cleanForm() {
      this.$refs.phone.$data.activeCountryCode = "US";
      this.$refs.phone.$data.phone = "";
      this.state.email = "";
    },
  },
};
</script>

<style lang="scss">
.form-container {
  padding-top: 20px;
  -webkit-transition: padding-top 0.25s;
  transition: padding-top 0.25s;
  text-align: left;
  .input-errors {
    position: absolute;
    bottom: -0.5em;
    .error-msg {
      color: red;
    }
  }

  .localize-form {
    display: inline-block;
    height: 60px;
    border: none;
    width: 512px;
    position: relative;
    text-align: left;
    border-radius: 50px;
    background: #fff;
    box-shadow: 0 9px 24px 0 rgb(0 0 0 / 12%);
    .localize-input {
      border: none;
      outline: none;
      padding: 30px 0;
      height: 60px;
      margin-left: 20px;
      width: 100%;
      font-family: Poppins Regular, sans-serif;
      border-radius: 50px;
    }
    .vue-tel-input {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      display: flex;
      border: none;
      .vti__dropdown {
        right: auto;
        left: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 1px;
        box-sizing: border-box;
        width: 65px;
        border-radius: 50px 0 0 50px;
        padding-left: 20px;
      }
      .vti__input {
        padding-left: 85px;
        border-radius: 50px;
      }
      .vti__dropdown-list.below {
        top: 50px;
      }
      .vti__dropdown-list {
        position: absolute;
        z-index: 2;
        list-style: none;
        text-align: left;
        padding: 0px;
        margin: 0px 0px 0px -1px;
        box-shadow: rgb(0 0 0 / 20%) 1px 1px 4px;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(204, 204, 204);
        white-space: nowrap;
        max-height: 200px;
        overflow-y: scroll;
        color: black;
      }
    }
  }
  @media screen and(max-width:300px) {
    .localize-form,
    .input-wrapper,
    .btn {
      height: 50px !important;
    }
  }
}
</style>
