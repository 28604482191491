export const getters = {
  getUid: (state) => {
    return state.uid;
  },

  getRegisters: (state) => {
    return state.registers;
  },
  getEmail: (state) => {
    return state.email;
  },
  getUsername: (state) => {
    return state.username;
  },

  getCreditsSpent: (state) => {
    return state.credits_spent;
  },
  getCredits: (state) => {
    return state.credits;
  },
  getReminder: (state) => {
    return state.credits - state.credits_spent;
  },
  getLstPlan: (state) => {
    return state.Last_plan;
  },
};
