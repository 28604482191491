<script setup>
import { onMounted, defineEmits } from "vue";
const fundingSources = [paypal.FUNDING.PAYPAL, paypal.FUNDING.CARD];

const emit = defineEmits({
  onApprove: (datas) => {
    return datas;
  },
  onError: (error) => {
    return error;
  },
});
const props = defineProps({
  id: Number,
  amount: {
    value: { type: String, required: true, default: "3" },
    currency_code: { type: String, required: true, default: "USD" },
  },
});

onMounted(() => {
  for (const fundingSource of fundingSources) {
    const paypalButtonsComponent = paypal.Buttons({
      fundingSource: fundingSource,
      style: {
        shape: "pill",
        height: 40,
      },
      createOrder: (data, actions) => {
        const createOrderPayload = {
          purchase_units: [
            {
              amount: props.amount,
            },
          ],
        };

        return actions.order.create(createOrderPayload);
      },
      onApprove(data, actions) {
        return actions.order.capture().then(function (orderData) {
          // Full available details
          emit("onApprove", { ...orderData, plan_id: props.id });
        });
      },
      onError(err) {
        emit("onError", err);
      },
    });

    paypalButtonsComponent
      .render(`#paypal-button-container-b-${props.id}`)
      .catch((err) => {
        console.error("PayPal Buttons failed to render");
      });
  }
});
</script>

<template>
  <div :id="`paypal-button-container-b-${props.id}`" class="m-3"></div>
</template>
