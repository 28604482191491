import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export default {
  namespaced: true,
  state: {
    username: "User",
    Last_plan: "",
    credits: 0,
    credits_spent: 0,
    email: null,
    uid: null,
    photo: "",

    registers: [],
  },
  getters,
  mutations,
  actions,
};
