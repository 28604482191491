<template>
  <transition name="fade">
    <div
      class="modal-overlay"
      v-if="showLoading"
      style="
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="loading-container">
        <div class="loading"></div>
        <div id="loading-text">Loading</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showLoading: Boolean,
  },
};
</script>

<style lang="scss"></style>
