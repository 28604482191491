import Cookies from "js-cookie";
export const mutations = {
  updateUser: (state, payload) => {
    var cookies = {};
    if (Cookies.get("user")) {
      cookies = JSON.parse(Cookies.get("user"));
    }
    if (payload.Last_plan) {
      state.Last_plan = payload.Last_plan;
      cookies.Last_plan = payload.Last_plan;
    }

    if (payload.username) {
      state.username = payload.username;
      cookies.username = payload.username;
    }

    if (payload.email) {
      state.email = payload.email;
      cookies.email = payload.email;
    }
    if (payload.credits) {
      state.credits = payload.credits;
      cookies.credits = payload.credits;
    }

    if (payload.credits_spent >= 0) {
      state.credits_spent = payload.credits_spent;
      cookies.credits_spent = payload.credits_spent;
    }

    if (payload.uid) {
      state.uid = payload.uid;
      cookies.uid = payload.uid;
    }

    Cookies.set("user", JSON.stringify(cookies));
  },
  CleanUser: (state) => {
    if (Cookies.get("user")) {
      Cookies.remove("user");
    }
    state.username = null;
    state.email = null;
    state.uid = null;
    state.photo = "";
    state.credits = 0;
    state.credits_spent = 0;
  },
  updateRegisters: (state, payload) => {
    if (payload.registers) {
      state.registers = payload.registers;
    }
  },
  addRegister: (state, payload) => {
    state.registers = [payload, ...state.registers];
  },
};
