<template>
  <footer id="footer">
    <div class="container">
      <div class="columns column-2">
        <div class="address">
          <img
            src="@/assets/Logogeoloctel.png"
            style="max-width: 200px"
            alt=""
          />

          <span
            ><!-- 4 rue du coronel Dupont<br>  --><a
              href="mailto:info@geoloctel.com"
              title="contact us by email"
              ><i class="fa fa-envelope" style="margin-right: 5px"></i>
              info@geoloctel.com</a
            ></span
          >
          <span>Power By XLAB <img src="@/assets/XLAB.svg" alt="" width="25" height="25" /></span>
          
        </div>
      </div>
      <br />
      <br class="d-none d-sm-none d-md-block" />
      <div class="columns column-3 v-align-top">
        <div>
          <h3 class="d-sm-block d-md-none">Navigation</h3>
          <div class="columns column-2 sitemap-links">
            <div>
              <router-link to="/">Home page</router-link>
              <router-link to="/pricing">Rate</router-link>
              <router-link to="/faq">F.A.Q</router-link>
              <router-link to="/contact">Contact</router-link>
            </div>
            <div></div>
          </div>
        </div>
        <br class="d-sm-block d-md-none" />
        <br class="d-sm-block d-md-none" />
        <div class="languages">
          <h3 class="d-none d-sm-none d-md-block">language</h3>
          <button
            class="btn btn-outline modal-trigger"
            data-modal-link="languages"
            id="dropdown-languages"
          >
            English
          </button>
        </div>
        <br class="d-sm-block d-md-none" />
        <br class="d-sm-block d-md-none" />
        <div class="paiment-methods">
          <h3 class="v-align-solo v-align-bottom">
            Secure payment
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="#FFF"
                fill-rule="evenodd"
                d="M12 2c2.761 0 5 2.37 5 5.294v1.872h1a2 2 0 0 1 2 2V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-8.833a2 2 0 0 1 2-2l1-.001V7.294C7 4.37 9.239 2 12 2zm0 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm0-9.353c-1.38 0-2.5 1.185-2.5 2.647v1.872h5V7.294c0-1.462-1.12-2.647-2.5-2.647z"
              ></path>
            </svg>
          </h3>

          <img src="../assets/logopaypal.png" alt="" style="max-width: 100px" />
        </div>
        
      </div>
      <br class="d-none d-sm-none d-md-block" />
      <br />
      <div class="links d-flex justify-content-between">
        <span class="">©Geoloctel 2022</span>
        <router-link to="/cookies">Cookies policy & Privacy Policy</router-link>
        <router-link to="/conditions">General terms and conditions</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "gFooter",
};
</script>

<style lang="scss">
#footer {
  background-image: url("../assets/footer.png");
  background-size: cover;
  height: 100%;
  .container {
    padding-top: 76px;
  }
  .logo {
    padding-right: 20px;
  }
  .address {
    a,
    span {
      color: #fff;
      font-family: Poppins Regular, sans-serif;
    }
    span {
      display: inline-block;
      font-size: 14px;
      vertical-align: middle;
      border-left: 1px solid hsla(0, 0%, 100%, 0.5);
      padding-left: 20px;
    }
  }
  .localize {
    text-align: right;
  }
  h3 {
    color: #fff;
    margin-bottom: 15px;
    font-family: Poppins SemiBold, sans-serif;
    text-transform: uppercase;
  }
  .sitemap-links {
    a,
    span {
      cursor: pointer;
      width: 100%;
      display: inline-block;
      margin-bottom: 10px;
      color: #fff;
    }
    a,
    span {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .links {
    > * {
      color: #fff;
      font-family: Poppins Regular, sans-serif;
      margin-right: 15px;
    }
    .copyright {
      margin-right: 73px;
    }
  }
  @media screen and(max-width:990px) {
    height: auto;
  }
}
</style>
