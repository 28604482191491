import Cookies from "js-cookie";
export const actions = {
  async initializedApp({ dispatch }) {
    //var cookies;
    if (Cookies.get("auth")) {
      //cookies = JSON.parse(Cookies.get("auth"));
      await dispatch("Auth/refreshToken", null, { root: true });
      await dispatch("User/onRegistersChange", null, { root: true });
    }

    await dispatch("Auth/stateChanged", null, { root: true });
  },
};
