<template>
  <form id="gloginForm" @submit.prevent="send">
    <div class="form-group">
      <label for="email">Email address</label>
      <input
        type="email"
        class="form-control"
        v-model="state.email"
        autocomplete="email"
      />
      <div
        class="input-errors"
        v-for="error of v$.email.$errors"
        :key="error.$uid"
      >
        <small class="error-msg">{{ error.$message }}</small>
      </div>
    </div>

    <div class="form-group">
      <label for="password">password</label>
      <input
        type="password"
        class="form-control"
        autocomplete="current-password"
        v-model="state.password"
      />
      <div
        class="input-errors"
        v-for="error of v$.password.$errors"
        :key="error.$uid"
      >
        <small class="error-msg">{{ error.$message }}</small>
      </div>
    </div>

    <div class="actions">
      <router-link to="/reset">Forgot your password</router-link>
      <button class="btn btn-green modal-trigger">LOG IN</button>

      <span
        class="modal-trigger"
        @click="
          $emit('changeForm', { idForm: 2, titleForm: 'Create an account' })
        "
        >I don't have an account yet</span
      >
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { mapActions } from "vuex";
export default {
  name: "gLoginForm",
  setup() {
    const state = reactive({
      email: "",
      password: "",
    });

    const rules = computed(() => {
      return {
        email: {
          required: helpers.withMessage(
            "The field password* must have a value.",
            required
          ),
          email: helpers.withMessage(
            "The field Email address must have a value.",
            email
          ),
        },
        password: {
          required: helpers.withMessage(
            "The field password* must have a value.",
            required
          ),
        },
      };
    });

    const v$ = useVuelidate(rules, state);
    return {
      state,
      v$,
    };
  },
  methods: {
    ...mapActions(["LocalLogin"]),
    async send() {
      if (await this.v$.$validate()) {
        try {
          var payload = {
            email: this.state.email,
            password: this.state.password,
          };
          this.$root.loading = true;
          await this.LocalLogin(payload);
          this.$emit("changeForm", { idForm: 0, titleForm: "Logging in" });
          this.$root.loading = false;

          this.$notify({
            group: "basic",
            speed: 1000,
            title: "SUCCESS!",
            text: "WELCOME TO GELOCTEL",
            type: "success",
            duration: 5000,
          });
          this.$router.push("/Profile");
        } catch (error) {
          this.$root.loading = false;
          if (error) {
            this.$notify({
              group: "basic",
              speed: 1000,
              title: "OOPS!",
              text: error.message,
              type: "error",
              duration: 5000,
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
#gloginForm {
  .error-msg {
    color: red;
  }
}
</style>
