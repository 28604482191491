import Cookies from "js-cookie";
export const getters = {
  getIdToken: (state) => {
    return state.idToken;
  },
  getRefreshToken: () => {
    var cookies = {};
    if (Cookies.get("auth")) {
      cookies = JSON.parse(Cookies.get("auth"));
    }
    return cookies.refreshToken;
  },
  getLogin: (state) => {
    return state.login;
  },
  getLocalId: (state) => {
    return state.localId;
  },
  getRegistered: (state) => {
    return state.registered;
  },
  getExpiresIn: (state) => {
    return state.expiresIn;
  },
  getRefreshInterval: (state) => {
    return state.refreshInterval;
  },
  getAuth: (state) => {
    return {
      idToken: state.idToken,
      expiresIn: state.expiresIn,
      localId: state.localId,
      refreshToken: state.refreshToken,
      login: state.login,
      refreshInterval: state.refreshInterval,
    };
  },
};
