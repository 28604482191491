<template>
  <div id="gAccordion" class="row">
    <div class="card" @click="$emit('onClick', name)">
      <div class="card-body">
        <h2 class="card-title text-center"><slot name="title"></slot></h2>
        <h4 class="card-subtitle mb-3 text-muted text-center">
          <slot name="subtitle"></slot>
        </h4>
        <div class="card-content">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gAccordion",
  props: ["name"],
};
</script>

<style lang="scss">
#gAccordion {
  .card {
    max-height: 2.5em;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    padding-top: 0;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

    cursor: pointer;
    .card-body {
      padding-top: 5px;
      .card-title {
        font-size: 1.5em;
      }
      .card-subtitle {
        font-size: 2em;
      }
    }
  }
}
</style>
