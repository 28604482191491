<template>
  <section id="pricing">
    <div class="container">
      <div class="card pricing-card margin-center">
        <div class="card-header" style="border-radius: 20px">
          <h1>CREDITS</h1>
          <span>Different plans, choose the right one!</span>
        </div>
        <div class="card-content text-center container">
          <div class="pricing-offer p-5 row justify-content-around">
            <div class="price two col-md-3 mb-4">
              <span class="trial-text">10 Credits</span>
              <span>$15,95 </span>
            </div>
            <div class="price one col-md-3 mb-4">
              <span class="trial-text">20 Credits</span>
              <span>$21,95 </span>
            </div>
            <div class="price two col-md-3 mb-4">
              <span class="trial-text">30 Credits</span>
              <span>$30 </span>
            </div>
          </div>
          <div class="pricing-features">
            <!-- <div class="features-list columns column-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                >
                  <defs>
                    <path id="a" d="M0 0h1440v1318H0z"></path>
                    <rect id="c" width="580" height="790" rx="20"></rect>
                    <filter
                      id="d"
                      width="114%"
                      height="110.3%"
                      x="-7%"
                      y="-4%"
                      filterUnits="objectBoundingBox"
                    >
                      <feOffset
                        dy="9"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                      ></feOffset>
                      <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="12"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.121776661 0"
                      ></feColorMatrix>
                    </filter>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <path fill="#F7F8F6" d="M-531-566H909V923H-531z"></path>
                    <g opacity=".5" transform="translate(-531 -466)">
                      <mask id="b" fill="#fff">
                        <use xlink:href="#a"></use>
                      </mask>
                      <use
                        fill="#F7F8F6"
                        transform="matrix(1 0 0 -1 0 1318)"
                        xlink:href="#a"
                      ></use>
                      <path
                        fill="#EEE"
                        d="M134.19 5.561v114.931c0 149.46 322.919 3.978 484.919 199.91 162 195.931 6.466 483.598-219.054 504.41C249.71 838.688 116.357 691.314 0 382.692V5.561h134.19z"
                        mask="url(#b)"
                      ></path>
                      <path
                        fill="#F2F2F2"
                        d="M-209 261c240.25 0 445.054 126.941 445.054 379S392.961 1019 636 1019h376v.004l.5-.004c164.302 0 297.806 131.861 300.46 295.53l.04 4.97c0 165.962-134.538 300.5-300.5 300.5-155.322 0-283.121-117.842-298.868-268.997L-209 1351V261z"
                        mask="url(#b)"
                      ></path>
                    </g>
                    <g transform="translate(-101 -379)">
                      <mask id="e" fill="#fff">
                        <use xlink:href="#c"></use>
                      </mask>
                      <use fill="#000" filter="url(#d)" xlink:href="#c"></use>
                      <use fill="#FFF" xlink:href="#c"></use>
                      <g mask="url(#e)">
                        <g
                          stroke-width="2.25"
                          transform="translate(110.75 388.75)"
                        >
                          <circle
                            cx="20.25"
                            cy="20.25"
                            r="19.125"
                            fill="#5bbcc1"
                            stroke="#0B2B33"
                          ></circle>
                          <path
                            stroke="#0B2B33"
                            stroke-linecap="round"
                            d="M14.597 24c1.47 0 3.354-1.25 5.653-3.75-2.3-2.5-4.184-3.75-5.653-3.75-2.203 0-3.99 1.679-3.99 3.75 0 2.071 1.787 3.75 3.99 3.75zM25.903 16.5c-1.47 0-3.354 1.25-5.653 3.75 2.3 2.5 4.184 3.75 5.653 3.75 2.203 0 3.99-1.679 3.99-3.75 0-2.071-1.787-3.75-3.99-3.75z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span
                  >Geolocation <br />
                  unlimited</span
                >
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                >
                  <defs>
                    <path id="a" d="M0 0h1440v1318H0z"></path>
                    <rect id="c" width="580" height="790" rx="20"></rect>
                    <filter
                      id="d"
                      width="114%"
                      height="110.3%"
                      x="-7%"
                      y="-4%"
                      filterUnits="objectBoundingBox"
                    >
                      <feOffset
                        dy="9"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                      ></feOffset>
                      <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="12"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.121776661 0"
                      ></feColorMatrix>
                    </filter>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <path fill="#F7F8F6" d="M-691-566H749V923H-691z"></path>
                    <g opacity=".5" transform="translate(-691 -466)">
                      <mask id="b" fill="#fff">
                        <use xlink:href="#a"></use>
                      </mask>
                      <use
                        fill="#F7F8F6"
                        transform="matrix(1 0 0 -1 0 1318)"
                        xlink:href="#a"
                      ></use>
                      <path
                        fill="#F2F2F2"
                        d="M-209 261c240.25 0 445.054 126.941 445.054 379S392.961 1019 636 1019h376v.004l.5-.004c164.302 0 297.806 131.861 300.46 295.53l.04 4.97c0 165.962-134.538 300.5-300.5 300.5-155.322 0-283.121-117.842-298.868-268.997L-209 1351V261z"
                        mask="url(#b)"
                      ></path>
                    </g>
                    <g transform="translate(-261 -379)">
                      <mask id="e" fill="#fff">
                        <use xlink:href="#c"></use>
                      </mask>
                      <use fill="#000" filter="url(#d)" xlink:href="#c"></use>
                      <use fill="#FFF" xlink:href="#c"></use>
                      <g mask="url(#e)">
                        <g transform="translate(278.25 387.25)">
                          <rect
                            width="24.75"
                            height="40.5"
                            x="1.125"
                            y="1.125"
                            fill="#5bbcc1"
                            stroke="#0B2B33"
                            stroke-width="2.25"
                            rx="7.5"
                          ></rect>
                          <rect
                            width="7.5"
                            height="4.5"
                            x="9.75"
                            y=".75"
                            fill="#0B2B33"
                            rx="2.25"
                          ></rect>
                          <g transform="translate(7.5 19.5)">
                            <circle
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="1.5"
                              cy="6"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="6"
                              cy="1.5"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="6"
                              cy="6"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="10.5"
                              cy="1.5"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="10.5"
                              cy="6"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="1.5"
                              cy="10.5"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="6"
                              cy="10.5"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="6"
                              cy="15"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                            <circle
                              cx="10.5"
                              cy="10.5"
                              r="1.5"
                              fill="#0B2B33"
                            ></circle>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>All phone types</span>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                >
                  <defs>
                    <path id="a" d="M0 0h1440v1318H0z"></path>
                    <rect id="c" width="580" height="790" rx="20"></rect>
                    <filter
                      id="d"
                      width="114%"
                      height="110.3%"
                      x="-7%"
                      y="-4%"
                      filterUnits="objectBoundingBox"
                    >
                      <feOffset
                        dy="9"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                      ></feOffset>
                      <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="12"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.121776661 0"
                      ></feColorMatrix>
                    </filter>
                    <circle id="f" cx="20.25" cy="20.25" r="20.25"></circle>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <path fill="#F7F8F6" d="M-851-566H589V923H-851z"></path>
                    <g opacity=".5" transform="translate(-851 -466)">
                      <mask id="b" fill="#fff">
                        <use xlink:href="#a"></use>
                      </mask>
                      <use
                        fill="#F7F8F6"
                        transform="matrix(1 0 0 -1 0 1318)"
                        xlink:href="#a"
                      ></use>
                      <path
                        fill="#F2F2F2"
                        d="M-209 261c240.25 0 445.054 126.941 445.054 379S392.961 1019 636 1019h376v.004l.5-.004c164.302 0 297.806 131.861 300.46 295.53l.04 4.97c0 165.962-134.538 300.5-300.5 300.5-155.322 0-283.121-117.842-298.868-268.997L-209 1351V261z"
                        mask="url(#b)"
                      ></path>
                    </g>
                    <g transform="translate(-421 -379)">
                      <mask id="e" fill="#fff">
                        <use xlink:href="#c"></use>
                      </mask>
                      <use fill="#000" filter="url(#d)" xlink:href="#c"></use>
                      <use fill="#FFF" xlink:href="#c"></use>
                      <g mask="url(#e)">
                        <g transform="translate(421 379)">
                          <g transform="translate(9.75 9.75)">
                            <mask id="g" fill="#fff">
                              <use xlink:href="#f"></use>
                            </mask>
                            <use fill="#5bbcc1" xlink:href="#f"></use>
                            <path
                              fill="#0B2B33"
                              d="M26.25 1.5h8.25l8.18 8.943v6.807h-8.93a7.5 7.5 0 0 1-7.5-7.5V1.5z"
                              mask="url(#g)"
                            ></path>
                            <path
                              fill="#0B2B33"
                              d="M18.757 31.378V20.072l11.68 6.572 4.75 13.677v6.807h-8.93a7.5 7.5 0 0 1-7.5-7.5v-8.25z"
                              mask="url(#g)"
                              transform="rotate(90 26.972 33.6)"
                            ></path>
                            <circle
                              cx="16.125"
                              cy="6.375"
                              r="4.875"
                              fill="#0B2B33"
                              mask="url(#g)"
                            ></circle>
                            <path
                              fill="#0B2B33"
                              d="M.75 1.5H15v10.875a7.125 7.125 0 0 1-14.25 0V1.5z"
                              mask="url(#g)"
                            ></path>
                          </g>
                          <circle
                            cx="30"
                            cy="30"
                            r="19.125"
                            stroke="#0B2B33"
                            stroke-width="2.25"
                          ></circle>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span
                  >Worldwide <br />
                  coverage</span
                >
              </div>
            </div>
            <div class="features-list columns column-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                >
                  <defs>
                    <path id="a" d="M0 0h1440v1318H0z"></path>
                    <rect id="c" width="580" height="790" rx="20"></rect>
                    <filter
                      id="d"
                      width="114%"
                      height="110.3%"
                      x="-7%"
                      y="-4%"
                      filterUnits="objectBoundingBox"
                    >
                      <feOffset
                        dy="9"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                      ></feOffset>
                      <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="12"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.121776661 0"
                      ></feColorMatrix>
                    </filter>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <path fill="#F7F8F6" d="M-610-680H830V809H-610z"></path>
                    <g opacity=".5" transform="translate(-610 -580)">
                      <mask id="b" fill="#fff">
                        <use xlink:href="#a"></use>
                      </mask>
                      <use
                        fill="#F7F8F6"
                        transform="matrix(1 0 0 -1 0 1318)"
                        xlink:href="#a"
                      ></use>
                      <path
                        fill="#EEE"
                        d="M134.19 5.561v114.931c0 149.46 322.919 3.978 484.919 199.91 162 195.931 6.466 483.598-219.054 504.41C249.71 838.688 116.357 691.314 0 382.692V5.561h134.19z"
                        mask="url(#b)"
                      ></path>
                      <path
                        fill="#F2F2F2"
                        d="M-209 261c240.25 0 445.054 126.941 445.054 379S392.961 1019 636 1019h376v.004l.5-.004c164.302 0 297.806 131.861 300.46 295.53l.04 4.97c0 165.962-134.538 300.5-300.5 300.5-155.322 0-283.121-117.842-298.868-268.997L-209 1351V261z"
                        mask="url(#b)"
                      ></path>
                    </g>
                    <g transform="translate(-180 -493)">
                      <mask id="e" fill="#fff">
                        <use xlink:href="#c"></use>
                      </mask>
                      <use fill="#000" filter="url(#d)" xlink:href="#c"></use>
                      <use fill="#FFF" xlink:href="#c"></use>
                      <g mask="url(#e)">
                        <path
                          fill="#5bbcc1"
                          stroke="#0B2B33"
                          stroke-width="2.25"
                          d="M194.625 538.375h6.748l.25.136a17.54 17.54 0 0 0 8.377 2.114c9.734 0 17.625-7.891 17.625-17.625s-7.891-17.625-17.625-17.625-17.625 7.891-17.625 17.625c0 2.968.733 5.827 2.115 8.378l.135.251v6.746z"
                        ></path>
                        <g transform="rotate(45 -512.512 509.274)">
                          <circle
                            cx="11.625"
                            cy="11.625"
                            r="1.875"
                            fill="#0B2B33"
                          ></circle>
                          <path
                            stroke="#0B2B33"
                            stroke-linecap="round"
                            stroke-width="2.25"
                            d="M13.125 5.25a7.875 7.875 0 0 0-7.875 7.875M13.125 0C5.876 0 0 5.876 0 13.125"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>All networks</span>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                >
                  <defs>
                    <path id="a" d="M0 0h1440v1318H0z"></path>
                    <rect id="c" width="580" height="790" rx="20"></rect>
                    <filter
                      id="d"
                      width="114%"
                      height="110.3%"
                      x="-7%"
                      y="-4%"
                      filterUnits="objectBoundingBox"
                    >
                      <feOffset
                        dy="9"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                      ></feOffset>
                      <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="12"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.121776661 0"
                      ></feColorMatrix>
                    </filter>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <path fill="#F7F8F6" d="M-771-680H669V809H-771z"></path>
                    <g opacity=".5" transform="translate(-771 -580)">
                      <mask id="b" fill="#fff">
                        <use xlink:href="#a"></use>
                      </mask>
                      <use
                        fill="#F7F8F6"
                        transform="matrix(1 0 0 -1 0 1318)"
                        xlink:href="#a"
                      ></use>
                      <path
                        fill="#F2F2F2"
                        d="M-209 261c240.25 0 445.054 126.941 445.054 379S392.961 1019 636 1019h376v.004l.5-.004c164.302 0 297.806 131.861 300.46 295.53l.04 4.97c0 165.962-134.538 300.5-300.5 300.5-155.322 0-283.121-117.842-298.868-268.997L-209 1351V261z"
                        mask="url(#b)"
                      ></path>
                    </g>
                    <g transform="translate(-341 -493)">
                      <mask id="e" fill="#fff">
                        <use xlink:href="#c"></use>
                      </mask>
                      <use fill="#000" filter="url(#d)" xlink:href="#c"></use>
                      <use fill="#FFF" xlink:href="#c"></use>
                      <g mask="url(#e)">
                        <path
                          fill="#5bbcc1"
                          stroke="#0B2B33"
                          stroke-width="2.25"
                          d="M373.166 540.633c8.156-9.765 12.209-17.194 12.209-22.133 0-8.491-6.884-15.375-15.375-15.375s-15.375 6.884-15.375 15.375c0 4.94 4.053 12.368 12.209 22.133a4.125 4.125 0 0 0 6.332 0zM370 523.375a4.875 4.875 0 1 1 0-9.75 4.875 4.875 0 0 1 0 9.75z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Exact location</span>
              </div>
            </div> -->
            <span
              v-if="!$store.getters['Auth/getLogin']"
              class="btn modal-trigger"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#gModal"
              @click="
                $emit('changeForm', {
                  idForm: 2,
                  titleForm: 'Create account',
                })
              "
              >BUY CREDITS</span
            >
            <router-link
              to="/profile"
              v-else
              class="btn modal-trigger"
              type="button"
              >BUY CREDITS</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
window.scroll({
  top: 0,

  behavior: "smooth",
});
</script>
<style lang="scss">
#pricing {
  .modal-trigger {
    background: linear-gradient(rgba(255, 0, 0, 0.582), #e80000);
    border: 3px solid red;
  }
  background-image: url("../assets/motif-ligth.png");
  background-size: cover;
  padding: 187px 0 67px;
  background-repeat: no-repeat;
  .card.pricing-card {
    width: 580px;
    margin: 0 40px 0 0;
    @media screen and(max-width:600px) {
      width: 100%;
    }
    .card-header {
      background-image: url("@/assets/footer.png");
      background-size: 100%;
      text-align: center;
      padding: 22px;
      background-color: transparent;
      border: none;
      &:first-child {
        border-radius: calc(1.25rem - 1px) calc(1.25rem - 1px) 0 0;
      }
      h1 {
        color: #fff;
        font-size: 45px;
        margin-bottom: 10px;
      }
      span {
        color: #fff;
      }
      @media screen and (max-width: 380px) {
        padding-top: 10px;
        padding-bottom: 10px;
        h1 {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 990px) {
        h1 {
          font-size: 24px;
        }
      }
    }
    .pricing-offer {
      .one {
        background-image: radial-gradient(
          circle farthest-side,
          #fceabb,
          #f8b500
        );
      }
      .two {
        background-image: linear-gradient(
          95.2deg,
          rgba(173, 252, 234, 1) 26.8%,
          rgba(192, 229, 246, 1) 64%
        );
      }
      .price {
        display: inline-block;
        border-radius: 10px;
        position: relative;
        padding: 15px 0;

        color: #616161;
        font-family: Poppins Regular, sans-serif;
        box-shadow: 0 10px 15px rgba(78, 78, 78, 0.425);

        span {
          width: 100%;
          font-size: 24px;
          display: inline-block;
          margin-bottom: 10px;
          color: #000;
        }
        .trial-text {
          font-family: Poppins Medium, sans-serif;
          text-align: center;
          color: #0b2b33;
          font-size: 20px;
          font-weight: 600;
        }
        @media screen and (max-width: 380px) {
          .trial-text {
            font-size: 16px;
          }
        }
      }
      > span {
        font-size: 14px;
        color: #525252;
        padding: 0 30px;
        font-family: Poppins Regular, sans-serif;
        @media screen and (max-width: 380px) {
          padding: 0 15px;
        }
      }
    }
    .pricing-features {
      padding: 30px 50px 40px;
      .features-list.column-2 {
        padding: 0 90px;
      }
      .features-list span {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        color: #787878;
      }
      .btn {
        font-size: 25px;
      }
      @media screen and (max-width: 990px) {
        padding: 30px 10px 40px;
        .features-list {
          text-align: left;
        }
        .features-list.column-2 {
          padding: 0;
        }
        .columns {
          white-space: unset;
          width: 100%;
        }
        .columns.column-3 > :first-of-type {
          margin-right: 0;
        }
        .columns.column-3 > * {
          width: 100%;
        }
        .columns > * {
          display: block;
          margin-bottom: 20px;
        }
        .columns.column-2 > * {
          width: 100%;
        }
        .columns.column-2 > :first-of-type {
          margin-right: 0;
        }
      }
    }
    .card-footer {
      font-size: 14px;
      border: none;
      color: #525252;
      background-color: transparent;
      font-family: Poppins Regular, sans-serif;
      &:last-child {
        border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
      }
    }
  }
  .margin-center {
    margin: 0 auto !important;
  }
}
</style>
