<template>
  <form id="gConditions" @submit.prevent="send1" style="max-width: 30rem">
    <div class="form-group">
      <label
        style="
          font-size: 14px;
          font-family: Poppins Regular, sans-serif;
          margin-bottom: 10px;
          color: #525252;
        "
        >General terms and conditions</label
      >
      <div class="card-content">
        <div class="float-left w-100 cgu-details">
          <p class="mb-4 cgu-text">
            NEXTGENCLOUD SAS ("We") is a Panamanian company located at Atrium
            Tower, Panama City, Panama which operates through the sites
            <a href="https://phonenigma.com ">https://phonenigma.com</a> and
            <a href=" https://geoloctel.com"> https://geoloctel.com</a>
            ("Site") a geolocation service allowing any individual acting in a
            private capacity ("Client" or "You") to locate a mobile phone with
            the prior consent of the holder of the telephone number whose
            location is sought ("Service").
          </p>
          <p class="mb-4 cgu-text">
            The purpose of these Terms is to define how You, as a Customer, may
            benefit from the Service (&quot;Terms&quot;).
          </p>
          <p class="mb-4 cgu-text">
            Our Conditions are likely to be modified and supplemented at regular
            intervals according to the evolution, hypothesis in which We will
            inform You by e-mail the month preceding their update. Accessing and
            using Our Service after You have been informed of this update, as
            well as the absence of termination of Your subscription by the end
            of the month, implies Your adherence to the latest version of the
            said Terms.
          </p>
          <p class="mb-4 cgu-text">
            This version of Our Terms and Conditions is dated 07/07/2022.
          </p>
          <p class="mt-4 mb-4 cgu-head">1. registration</p>
          <p class="mb-4 cgu-text">
            <b>1.1</b> Access to and use of Our Service requires the opening of
            an account through Our Site (&quot;Account&quot;).
          </p>
          <p class="cgu-text">
            <b>1.2</b> At the time of Your registration, You will be asked for
            the following information in particular:
          </p>
          <ul class="mb-4">
            <li class="cgu-text">Email address</li>
            <li class="cgu-text">Password</li>
          </ul>
          <p class="mb-4 cgu-text">
            As a Customer, You guarantee the accuracy, sincerity and reliability
            of the information communicated to Us, as well as the fact that You
            are of legal age and have the right to subscribe to Our Service in
            Your country of residence.
          </p>
          <p class="mb-4 cgu-text">
            We draw Your attention to the fact that We reserve the right to take
            any legal action against You, including criminal proceedings, in the
            event of identity theft.
          </p>
          <p class="mb-4 cgu-text">
            <b>1.3</b> Your subscription contract is only validly concluded once
            the acceptance of the payment made pursuant to Article 2 below has
            been confirmed (&quot;Contract&quot;). You may download Your
            Contract at any time by sending Us a request to this effect by
            email.
          </p>
          <p class="mb-4 cgu-text">
            <b>1.4</b> The opening of the Account only confers the right to
            access and use our platform to the Customer who has opened the
            Account. As a Customer, it is therefore Your responsibility to keep
            the access codes to the Service (login and password) confidential
            and to inform Us of any abuse in this regard. Any transmission of
            access to third parties without Our prior consent is prohibited.
          </p>
          <p class="mb-4 cgu-text">
            <b>1.5</b> Access to Our Service is restricted to individuals using
            it for personal purposes. Any use for professional purposes is
            prohibited and will result in the immediate termination of Your
            subscription and, consequently, the possibility of accessing and
            using Our Service, without prior notice and without any possible
            refund.
          </p>
          <p class="mt-4 mb-4 cgu-head">2. PAYMENT</p>
          <p class="cgu-text">
            <b>2.1</b> The price of the Service are demonstrated on our pricing
            page
            <a href="https://geoloctel.com/pricing"
              >https://geoloctel.com/pricing</a
            >
          </p>

          <p class="mb-4 cgu-text">
            <b>2.2</b> The above prices are inclusive of all taxes.
          </p>
          <p class="mb-4 cgu-text">
            <b>2.3</b> Payment is made through Our Site in euros by credit card
            (Visa, MasterCard, American Express of PayPal). The Site is equipped
            with a secure online payment system enabling the Customer to encrypt
            the transmission of his bank data. As part of Our payment process,
            We use an external supplier, Stripe, and do not store any data
            relating to your details and bank cards. For more information on
            this subject, We invite You to consult our privacy policy.
          </p>
          <p class="mt-4 mb-4 cgu-head">
            3. CANCELLATION AND THE RIGHT OF WITHDRAWAL
          </p>
          <p class="mb-4 cgu-text">
            <b>3.1</b> If you live in the EU A withdrawal request can be made
            within 14 (fourteen) days of sending the Confirmation by sending us
            such a request by email. A confirmation of withdrawal will be sent
            to You by email to the address You indicated during Your
            registration.
          </p>
          <p class="mb-4 cgu-text">
            <b>3.2</b> Once You have exercised Your right to withdraw, You will
            be refunded the amount You paid to Us within 5 (five) to 10 (ten)
            days of receipt of Your withdrawal request by an equivalent amount
            credited to the card You used to pay for the Service. However, this
            right to a refund is subject to the fact that You have not used Our
            Service; in the event that You have used Our Service, You understand
            that no refund will be given for the current month, in which case
            your right of withdrawal will be treated as a request for
            cancellation and will take effect at the end of the month concerned.
          </p>
          <p class="mb-4 cgu-text">
            <b>3.3</b> The right of withdrawal enshrined in this Article 3 does
            not apply if you are domiciled outside of the EU, in which case any
            such request will be treated as a request for cancellation not
            giving rise to a refund.
          </p>

          <p class="mt-4 mb-4 cgu-head">4. SERVICE OPERATION</p>
          <p class="mb-4 cgu-text">
            <b>4.1</b> Once your subscription contract has been concluded, all
            you have to do is indicate the number of the mobile phone you wish
            to geo-locate (&quot;Recipient&quot;). An SMS is then sent to the
            Recipient containing the message that the Customer will have
            previously written and stating his wish to geo-locate the mobile
            phone in question. The Recipient is then free to accept or refuse
            this request, at his or her sole discretion. You acknowledge that We
            are not able to guarantee that the Recipients will accept your
            geo-location request, and that any refusal will not give rise to any
            liability or right to any reimbursement whatsoever on Our part in
            respect of You.
          </p>
          <p class="mb-4 cgu-text">
            <b>4.2</b> You are entitled to send an unlimited number of Requests
            to the Recipients of Your choice for as long as Your Agreement
            continues.
          </p>
          <p class="cgu-text"><b>4.3</b> You are forbidden to:</p>
          <ul class="mb-4">
            <li class="cgu-text">
              Use the Site unlawfully or in violation of these Terms;
            </li>
            <li class="cgu-text">
              Sell, copy, rent, lease, loan, distribute, transfer or sub-license
              all or part of the content appearing on the Site, Our Service, or
              use Our Service for business purposes;
            </li>
            <li class="cgu-text">
              Attempt to obtain unauthorized access to Our systems or those of
              Our subcontractors or engage in any activity that may disrupt,
              diminish the quality or interfere with the performance or
              functionality of the Site and Our Service;
            </li>
            <li class="cgu-text">
              Use the Site for abusive purposes by voluntarily introducing a
              virus or any other malicious program;
            </li>
            <li class="cgu-text">Using our Service for spamming purposes;</li>
            <li class="cgu-text">
              Denigrate Our activities or engage in any behaviour that may
              damage Our reputation, whether through Our Service or outside it
              (for example on social networks).
            </li>
          </ul>
          <p class="mt-4 mb-4 cgu-head">5. TERMINATION</p>
          <p class="mb-4 cgu-text">
            <b>5.1</b> Termination at the initiative of the Client. You are at
            all times entitled to unsubscribe and terminate Your Contract simply
            by clicking on the &quot;Unsubscribe&quot; link in the menu at the
            top or bottom of the page of the site, it being specified that Your
            unsubscription will then take place either at the end of the Trial
            Period or at the end of the current month in which Your
            unsubscription request is made, in which case Your Account will be
            deactivated and access to and use of Our Service will be terminated.
            Subject to clause 3.3, no refund will be made.
          </p>
          <p class="mb-4 cgu-text">
            <b>5.2</b> Termination at the initiative of NEXTGENCLOUD SAS. We are
            also entitled to terminate Your contract for the end of a month, in
            which case We will inform You by sending an email to the address You
            communicated to Us at the time of Your registration. In the event of
            a breach of these Terms and Conditions, in particular but not
            limited to section 4.3, We reserve the right to suspend Your Account
            for the time necessary for the verifications that may need to be
            carried out and, where applicable, to terminate Your Contract with
            immediate effect; whether it is a suspension or a termination, You
            will be informed by prior notice given to the same email address.
          </p>
          <p class="mb-4 cgu-text">
            <b>5.3</b> Cancellation exercised in application of this article
            does not entitle You to any reimbursement, subject to the hypothesis
            that such cancellation is equivalent to the exercise of a right of
            withdrawal on Your part within the meaning of articles 3.1 and 3.3.
          </p>
          <p class="mt-4 mb-4 cgu-head">6. INTELLECTUAL PROPERTY</p>
          <p class="mb-4 cgu-text">
            <b>6.1</b> NEXTGENCLOUD SAS owns all intellectual property rights to
            our Site and its content, which rights also include the know-how
            related thereto.
          </p>
          <p class="mb-4 cgu-text">
            <b>6.2</b> By entering into the Agreement, We grant You the
            non-exclusive and non-transferable right to access and use our
            Services.
          </p>
          <p class="mt-4 mb-4 cgu-head">7. LIABILITY AND WARRANTY</p>
          <p class="mb-4 cgu-text">
            <b>7.1</b> NEXTGENCLOUD SAS disclaims all liability resulting from
            the performance of the Contract binding Us to the fullest extent
            permitted by applicable law.
          </p>
          <p class="mb-4 cgu-text">
            <b>7.2</b> Notwithstanding Article 7.1, NEXTGENCLOUD SAS shall make
            reasonable efforts to ensure 24/7 availability of the Service, but
            shall not be liable for any unavailability due to bugs, technical
            constraints and other maintenance operations, which shall not give
            rise to any right to any refund whatsoever.
          </p>
          <p class="mb-4 cgu-text">
            <b>7.3</b> The Site may contain links to other sites that are
            neither edited nor controlled by NEXTGENCLOUD SAS and on the proper
            functioning and content of which We cannot be held responsible under
            any circumstances.
          </p>
          <p class="mt-4 mb-4 cgu-head">8. MISCELLANEOUS</p>
          <p class="mb-4 cgu-text">
            <b>8.1</b> FORCE MAJEURE. The parties agree that, in the event that
            the performance of the Contract should prove impossible due to an
            event of force majeure, i.e. an unforeseeable event outside the
            sphere of control of either party, neither party may be held liable
            for the non-performance, failures or delays in the performance of
            any of its obligations that would be due to the occurrence of the
            said event. The performance of the Contract and the resulting
            obligations, including payment obligations, shall thus be suspended
            for as long as the force majeure event lasts, it being specified
            that the payment made for the month in which the force majeure event
            occurred shall nevertheless be retained. However, each party shall
            be entitled to exercise the right of termination set out in Article
            5.
          </p>
          <p class="mb-4 cgu-text">
            <b>8.2</b> VOID. In the event that any provision of these Terms is
            held to be invalid, such invalidity shall not affect the validity of
            the remaining Terms. The void clause will be replaced and
            interpreted in such a way as to ensure its validity by a clause as
            close as possible in spirit to the void clause.
          </p>
          <p class="cgu-text">
            <b>8.3</b> COMMUNICATION. Any communication to be addressed to
            NEXTGENCLOUD SAS can be mailed to :
          </p>
          <ul class="mb-4">
            <li class="cgu-text">
              By email at:
              <a href="mailto:info@phonenigma.com" target="_blank"
                >info@phonenigma.com</a
              >
            </li>
          </ul>
          <p class="mb-4 cgu-text">
            <b>8.4</b> APPLICABLE LAW AND COMPETENT COURT. The validity and
            performance of these Terms and the Contract shall be governed by
            Panamanian law. Any dispute arising directly or indirectly out of
            these Conditions and the Contract arising hereunder shall be
            submitted to the competent Court of the Customer's domicile where
            NEXTGENCLOUD SAS is the plaintiff, respectively to the Court of
            Panama City where the Customer is the plaintiff.
          </p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="state.chk" />
        <label class="form-check-label" for="flexCheckDefault">
          I have read and agree to the terms and conditions
        </label>
        <div
          class="input-errors"
          v-for="error of v$.chk.$errors"
          :key="error.$uid"
        >
          <small class="error-msg">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="text-center">
      <span
        class="btn btn-green provider-trigger"
        data-provider="hipay"
        @click="send"
      >
        CONTINUE
      </span>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, sameAs } from "@vuelidate/validators";
import { reactive, computed } from "vue";
export default {
  name: "gConditions",
  setup() {
    const state = reactive({
      chk: false,
    });
    const rules = computed(() => {
      return {
        chk: {
          sameAs: helpers.withMessage(
            "accept the terms and conditions",
            sameAs(true)
          ),
        },
      };
    });
    const v$ = useVuelidate(rules, state);
    return {
      state,
      v$,
    };
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    async send() {
      if (await this.v$.$validate()) {
        this.$emit("changeForm_3", { idForm: 4, titleForm: "Select a plan" });
      }
    },
  },
};
</script>

<style lang="scss">
.error-msg {
  color: red;
}
.cgu-content {
  border-radius: 10px;
  border: 1px solid #d5d5d5;
  background-color: #f7f7f7;
  font-size: 16px;
  overflow-y: scroll;
  height: 200px;
  padding: 12px 10px;
  font-family: Poppins Regular, sans-serif;
}
ul {
  list-style: none;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check label {
  cursor: pointer;
  font-family: Poppins SemiBold, sans-serif;
}
.modal-body {
  padding: 0 !important;
}
</style>
