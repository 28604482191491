<template>
  <div id="payment-paypal" class="content-modal container mb-2">
    <g-accordion
      class="mt-3"
      v-for="plan in plans"
      :key="plan.id"
      :class="{
        one: plan.id != 3,
        two: plan.id == 3,
        active: plan.name == planName,
      }"
      @onClick="ChangePlan"
      :name="plan.name"
    >
      <template v-slot:title> {{ plan.credits }} Credits </template>
      <template v-slot:subtitle>${{ plan.price }} </template>
      <template v-slot:content>
        <p class="pricing text-center">Try me</p>
        <paypalbutton
          :id="plan.id"
          :amount="{ value: plan.price, currency_code: 'USD' }"
          @onApprove="onApprove"
          @onError="onError"
        />
      </template>
    </g-accordion>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import gAccordion from "./gAccordion.vue";
import paypalbutton from "@/components/buttons/paypal.button.vue";

export default {
  setup() {},
  data() {
    return {
      planName: "Premium",
      paypalButtons: null,
    };
  },
  components: { gAccordion, paypalbutton },
  props: {
    plans: Array,
    datas: Object,
  },
  methods: {
    ...mapActions("Auth", ["LocalRegister"]),
    ChangePlan(plan) {
      this.planName = plan;
    },
    onError(err) {
      console.error(err);
      this.$notify({
        group: "basic",
        speed: 1000,
        title: "OOPs.",
        text: "a payment error has occurred.",
        type: "error",
        duration: 5000,
      });
    },
    onApprove: async function (orderDatas) {
      let payload = {
        ...this.datas,
        order_id: orderDatas.id,
        plan_id: orderDatas.plan_id,
      };
      this.$root.loading = true;
      await this.LocalRegister(payload);
      this.$notify({
        group: "basic",
        speed: 1000,
        title: "account created.",
        text: "now you can log in.",
        type: "success",
        duration: 5000,
      });
      this.$root.loading = false;
      //location.reload();
    },
  },
};
</script>

<style lang="scss">
#payment-paypal {
  .pricing {
    margin-top: 20px;
    font-size: 14px;
    font-family: Poppins Regular, sans-serif;
    margin-bottom: 20px;
    color: #525252;
  }
  .one > .card {
    width: 18rem;
    padding: 1%;
    background-image: linear-gradient(
      95.2deg,
      rgba(173, 252, 234, 1) 26.8%,
      rgba(192, 229, 246, 1) 64%
    );
    @media screen and(max-width:990px) {
      width: 15rem;
    }
    @media screen and(max-width:768px) {
      width: 21rem;
    }
    @media screen and(max-width: 500px) {
      width: 90% !important;
    }
  }
  .two > .card {
    width: 18rem;
    padding: 2%;
    background-image: radial-gradient(circle farthest-side, #fceabb, #f8b500);
    @media screen and(max-width:990px) {
      width: 15rem;
    }
    @media screen and(max-width:768px) {
      width: 21rem;
    }
    @media screen and(max-width: 500px) {
      width: 90% !important;
    }
  }

  .active {
    .card {
      max-height: fit-content;
      transition: max-height 0.25s ease-in;
    }
  }
}
</style>
