import { axiosApi, axiosFirebase } from "@/plugins/axios";
import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "@/plugins/firebase";

export const actions = {
  async stateChanged({ commit, dispatch }) {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        let { data } = await axiosApi.get("/users/" + user.uid);
        data.user.uid = user.uid;

        await dispatch("refreshToken");
        commit("User/updateUser", data.user, { root: true });
        await dispatch("User/findAllRegisters", null, { root: true });
      } else {
        commit("CleanAuth");
        commit("User/CleanUser", null, { root: true });
      }
    });
  },
  async HeartbeatToken({ getters, commit, dispatch }) {
    if (getters.getRefreshInterval != null) {
      clearInterval(getters.getRefreshInterval);
    }
    var refreshInterval = setTimeout(async function () {
      await dispatch("refreshToken");
    }, Math.round(getters.getExpiresIn) * 1000);

    commit("RefreshInterval", refreshInterval);
  },
  async refreshToken({ getters, commit, dispatch }) {
    try {
      let { data } = await axiosFirebase.post("", {
        grant_type: "refresh_token",
        refresh_token: getters.getRefreshToken,
      });
      var datas = {
        login: true,
        idToken: data.id_token,
        expiresIn: data.expires_in,
        refreshToken: data.refresh_token,
      };
      commit("UpdateAuth", datas);
      await dispatch("HeartbeatToken");
    } catch (error) {
      if (error) {
        dispatch("logout");
      }
    }
  },
  LocalLogin: {
    root: true,
    async handler({ commit }, payload) {
      try {
        var { email, password } = payload;
        await signInWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed in
            if (!userCredential.user.emailVerified) {
              throw new Error("Confirm you email.");
            }
            const token = userCredential._tokenResponse;

            var datas = {
              idToken: token.idToken,
              expiresIn: token.expiresIn,
              localId: token.localId,
              refreshToken: token.refreshToken,
              login: true,
            };
            commit("UpdateAuth", datas);

            //dispatch("refreshToken");
            //location.reload();
            // ...
          })
          .catch((error) => {
            if (error) {
              throw error;
            }
          });
      } catch (error) {
        if (error) {
          throw error;
        }
      }
    },
  },
  async logout({ commit }) {
    try {
      await signOut(auth);
      commit("CleanAuth");
      commit("User/CleanUser", null, { root: true });
      location.reload();
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
  async LocalRegister({ commit }, payload) {
    try {
      await axiosApi.post("/users", payload);
      commit("test");
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
  async resetPass({ commit }, payload) {
    await axiosApi.post("/reset", { email: payload });
    commit("test", "revisa tu bandeja de correos");
  },
  async verifyEmail({ commit }, payload) {
    try {
      let {
        data: { response },
      } = await axiosApi.get("/users/verifyemail/" + payload.email);
      return response;
    } catch (error) {
      commit("test");
      if (error) {
        throw error;
      }
    }
  },
  async restorePassword({ commit }, payload) {
    try {
      await axiosApi.post("/restore-password", payload);
      commit("test");
      return true;
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
};
