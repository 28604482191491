<template>
  <div id="gFormRegister">
    <form id="gRegisterForm-step-1" @submit.prevent="send">
      <div class="form-group">
        <label for="email">Email <span>*</span></label>
        <input
          type="email"
          autocomplete="email"
          v-model="state.email"
          class="form-control"
        />
        <div
          class="input-errors"
          v-for="error of v$.email.$errors"
          :key="error.$uid"
        >
          <small class="error-msg">{{ error.$message }}</small>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password<span>*</span></label>
        <input
          type="password"
          v-model="state.password"
          class="form-control"
          placeholder="*****"
        />
        <div class="col-auto">
          <span id="textExample2" class="form-text">
            It must contain 8-20 characters.
          </span>
        </div>
        <div
          class="input-errors"
          v-for="error of v$.password.$errors"
          :key="error.$uid"
        >
          <small class="error-msg">{{ error.$message }}</small>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Repit password<span>*</span> </label>
        <input
          type="password"
          v-model="state.confirm"
          class="form-control"
          placeholder="*****"
        />
        <div
          class="input-errors"
          v-for="error of v$.confirm.$errors"
          :key="error.$uid"
        >
          <small class="error-msg">{{ error.$message }}</small>
        </div>
      </div>
      <div class="actions">
        <button type="submit" class="btn btn-green modal-trigger">
          CONTINUE
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  minLength,
  sameAs,
} from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { mapActions } from "vuex";
export default {
  name: "gRegisterFormStep1",
  components: {},

  setup() {
    const state = reactive({
      email: "",
      password: "",
      confirm: "",
    });

    const rules = computed(() => {
      return {
        email: {
          required: helpers.withMessage(
            "The field Email address* must have a value.",
            required
          ),
          email: helpers.withMessage("Enter valid email address", email),
        },
        password: {
          required: helpers.withMessage(
            "The field password* must have a value.",
            required
          ),
          minLength: helpers.withMessage("missing characters", minLength(8)),
        },
        confirm: {
          required: helpers.withMessage(
            "The field password* must have a value.",
            required
          ),
          sameAs: helpers.withMessage(
            "passwords do not match",
            sameAs(state.password)
          ),
        },
      };
    });

    const v$ = useVuelidate(rules, state);
    return {
      state,
      v$,
    };
  },
  methods: {
    ...mapActions("Auth", ["localRegister", "verifyEmail"]),
    async send() {
      try {
        if (await this.v$.$validate()) {
          var payload = {
            email: this.state.email,
            password: this.state.password,
          };
          this.$emit("loading", { active: true });
          let result = await this.verifyEmail({ email: this.state.email });
          if (result) {
            this.$notify({
              group: "basic",
              speed: 1000,
              title: "OOPS!",
              text: "Email is registered.",
              type: "error",
              duration: 5000,
            });
            this.$emit("loading", { active: false });
            return;
          }
          this.$emit("loading", { active: false });
          this.$emit("changeForm_2", { idForm: 3, payload, titleForm: "" });
        }
      } catch (error) {
        if (error) {
          this.state.email = "";
          this.state.password = "";
          this.state.confirm = "";

          this.$emit("loading", { active: false });
          this.$notify({
            group: "basic",
            speed: 1000,
            title: "OOPS!",
            text: "An error has occurred, try again.",
            type: "error",
            duration: 5000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
#gFormRegister {
  .error-msg {
    color: red;
  }
  .form-group {
    label > span {
      color: green;
    }
  }
}
</style>
