<template>
  <div id="gNotifications">
    <notifications
      group="basic"
      class="notification"
      animation-name="v-fade-left"
      position="top left"
    >
      <template #body="props">
        <div class="my-notification">
          <div class="toast-content">
            <i
              class="fas fa-solid fa-check notify success"
              v-if="props.item.type == 'success'"
            ></i>
            <i
              class="fa-solid fa-triangle-exclamation notify warn"
              v-if="props.item.type == 'warn'"
            ></i>
            <i
              class="fa-solid fa-xmark notify error"
              v-if="props.item.type == 'error'"
            ></i>
            <div class="message">
              <span class="text text-1">{{ props.item.title }}</span>
              <span class="text text-2">{{ props.item.text }}</span>
            </div>
          </div>
          <i class="fa-solid fa-xmark close" @click="close"></i>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 0,
      animation: {
        enter: {
          opacity: [1, 0],
          translateX: [0, -300],
          scale: [1, 0.2],
        },
        leave: {
          opacity: 0,
          height: 0,
        },
      },
    };
  },
  methods: {
    show(group, type = "") {
      const text = `
        This is notification text!
        <br>
        Date: ${new Date()}
      `;
      this.$notify({
        group,
        title: `Test ${type} notification #${this.id++}`,
        text,
        type,
        data: {
          randomNumber: Math.random(),
        },
      });
    },
    close() {
      this.$notify({ clean: true });
    },
  },
};
</script>

<style lang="scss">
#gNotifications {
  .notification {
    z-index: 100002;
    overflow: hidden;
    margin-top: 20px;
    .my-notification {
      background: #fff;
      padding: 20px 35px 20px 25px;
      box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      border-radius: 12px;
      .toast-content {
        display: flex;
        align-items: center;
        .message {
          display: flex;
          flex-direction: column;
          margin: 0 20px;
        }
        .text {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
        .text-1 {
          font-weight: 600;
          color: #333;
        }
      }
      i.notify {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        min-width: 35px;
        color: #fff;
        font-size: 20px;
        border-radius: 50%;
      }
      .close {
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 5px;
        cursor: pointer;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }

      .success {
        background: #4070f4;
      }

      .warn {
        background: #ffb648;
      }

      .error {
        // background: #4278e5;
        background: #b82e24;
      }
    }
  }
  .v-fade-left-enter-active,
  .v-fade-left-leave-active,
  .v-fade-left-move {
    transition: all 0.5s;
  }
  .v-fade-left-enter,
  .v-fade-left-leave-to {
    opacity: 0;
    transform: translateX(-500px) scale(0.2);
  }
}
</style>
