<template>
  <div class="container-fluid" style="padding: 0 !important">
    <transition>
      <gModal1
        :title="titleForm"
        @changeForm="formChange"
        v-if="!this.$store.getters['Auth/getLogin'] & (idForm != 0)"
      >
        <gLoginForm
          v-if="idForm == 1"
          @changeForm="formChange"
          @loading="ActiveLoading"
        />
        <gRegisterFormStep1
          v-else-if="idForm == 2"
          @changeForm_2="formChange"
          @loading="ActiveLoading"
        />
        <GConditions v-else-if="idForm == 3" @changeForm_3="formChange" />
        <GPayment1
          v-else-if="idForm == 4"
          :datas="payload"
          @loading="ActiveLoading"
          :plans="$store.getters.getPlans"
        />
      </gModal1>
    </transition>
    <g-nav-bar @loading="ActiveLoading" @changeForm="formChange" />
    <main>
      <router-view @changeForm="formChange" @loading="ActiveLoading" />
      <gContactBanner />
    </main>
    <gFooter />

    <g-notifications />
    <gLoading1 :showLoading="loading" />
  </div>
</template>

<script>
import gNavBar from "./components/gNavBar.vue";
import gFooter from "./components/gFooter.vue";
import gContactBanner from "@/components/gContactBanner.vue";
import gLoginForm from "@/components/gLoginForm.vue";
import gRegisterFormStep1 from "@/components/gRegisterFormStep1.vue";
import { mapActions } from "vuex";
import GConditions from "./components/gConditions.vue";
import GPayment1 from "./components/gPayment1.vue";
import gLoading1 from "./components/gLoading1.vue";

import gModal1 from "./components/gModal1.vue";

import gNotifications from "./components/gNotifications.vue";
export default {
  components: {
    gNavBar,
    gFooter,
    gContactBanner,
    gLoginForm,
    gRegisterFormStep1,
    GConditions,
    GPayment1,
    gLoading1,
    gModal1,
    gNotifications,
  },
  data() {
    return {
      idForm: 0,
      loading: false,
      titleForm: "Log in",
      payload: {},
    };
  },
  async created() {
    await this.initializedApp();
  },
  methods: {
    ...mapActions(["initializedApp"]),
    formChange(datas) {
      let { idForm, titleForm, payload } = datas;
      this.idForm = idForm;
      this.titleForm = titleForm;
      if (payload) {
        this.payload = payload;
      }
    },
    async ActiveLoading(status) {
      this.loading = status.active;
    },
  },
};
</script>
<style lang="scss">
@import "@/sass/animations.scss";
#app {
  color: #2c3e50;
  @media (min-width: 1200px) {
    .container {
      max-width: 1024px;
    }
  }
}
</style>
