import { axiosApi } from "@/plugins/axios";
import { collection, db, onSnapshot } from "@/plugins/firebase";
export const actions = {
  async onRegistersChange({ getters }) {
    try {
      const q = collection(
        db,
        "users",
        "1wqRpjB7VqMMBYa51JDgtwjzcIs2",
        "registers"
      );
      onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          var datas = change.doc.data();

          if (datas.status === "localiced") {
            const registers = getters.getRegisters;
            registers.map((reg) => {
              if (change.doc.id == reg.id) {
                reg.status = datas.status;
              }
              return reg;
            });
          }
        });
      });
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
  async localice({ getters, commit }, payload) {
    try {
      if (getters.getReminder < 2) {
        throw Error;
      }

      const {
        data: { user, register },
      } = await axiosApi.post("/sms/send", payload);
      commit("updateUser", user);
      commit("addRegister", register);
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
  async sendSms({ getters, commit }, payload) {
    try {
      if (getters.getReminder < 2) {
        throw Error;
      }
      const {
        data: { user, register },
      } = await axiosApi.post("/sms/send", payload);
      commit("updateUser", user);
      commit("addRegister", register);
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
  async rechageCredits({ dispatch, getters }, payload) {
    try {
      await axiosApi.post("/users/recharge", payload);
      await dispatch("findOne", getters.getUid);
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
  async findOne({ commit }, payload) {
    try {
      let {
        data: { user },
      } = await axiosApi.get("/users/" + payload);
      commit("updateUser", user);
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
  async searchRegisters({ commit }, payload) {
    try {
      let { data } = await axiosApi.get(
        "/users/registers/search?filter=" + payload
      );
      commit("updateRegisters", data);
    } catch (error) {
      if (error) {
        commit("updateRegisters", { registers: [] });
        throw error;
      }
    }
  },
  async findAllRegisters({ commit }) {
    try {
      let { data } = await axiosApi.get("/users/registers");
      commit("updateRegisters", data);
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  },
};
