<template>
  <div id="home">
    <section id="landing">
      <div class="container columns column-2">
        <gForm class="form-container" @loading="ActiveLoading" />
        <div class="text-right phone-container col-5">
          <div class="d-none d-sm-none d-md-block">
            <picture>
              <img
                src="@/assets/changePhone.png"
                class="lazyload phone"
                loading="lazy"
                alt="landing_preview"
              />
            </picture>

            <div class="pin"></div>
            <div class="pulse"></div>

            <div class="card text-left">
              <span class="title"
                ><i class="fa fa-map-marker"></i>location found!</span
              >
              <div class="localise-detail">
                <span class="date">12/02/2022 . 11:34</span>
                <br />
                <span class="number">+1 484 521 9651</span>
                <br />
                <span class="address"
                  >841 Nicolet Ave, Winter Park <br />FL 32789, EE. UU.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="features">
      <div class="container columns column-2">
        <div class="container-features">
          <ul class="features-list">
            <li class="feature-item">
              <svg
                class="feature-img"
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
              >
                <g fill="none" fill-rule="evenodd">
                  <path
                    fill="#FFF"
                    stroke="#FFF"
                    stroke-width="2"
                    d="M50.667 11.667H29.333a6.99 6.99 0 0 0-7 7v42.666c0 3.875 3.126 7 7 7h21.334c3.874 0 7-3.125 7-7V18.667c0-3.875-3.126-7-7-7zM46.333 65H33.667v-4.667h12.666V65zM55 57H25V17.667h30V57z"
                  ></path>
                  <path d="M8 8h64v64H8z"></path>
                  <g transform="translate(21 8)">
                    <rect
                      width="35"
                      height="61"
                      x="2"
                      y="2"
                      fill="#5bbcc1"
                      stroke="#FFF"
                      stroke-width="4"
                      rx="10"
                    ></rect>
                    <rect
                      width="11"
                      height="6"
                      x="14"
                      fill="#FFF"
                      rx="3"
                    ></rect>
                    <g transform="translate(12 29)">
                      <circle cx="2" cy="2" r="2" fill="#0b2b33"></circle>
                      <circle cx="2" cy="8" r="2" fill="#0b2b33"></circle>
                      <circle cx="8" cy="2" r="2" fill="#0b2b33"></circle>
                      <circle cx="8" cy="8" r="2" fill="#0b2b33"></circle>
                      <circle cx="14" cy="2" r="2" fill="#0b2b33"></circle>
                      <circle cx="14" cy="8" r="2" fill="#0b2b33"></circle>
                      <circle cx="2" cy="14" r="2" fill="#0b2b33"></circle>
                      <circle cx="8" cy="14" r="2" fill="#0b2b33"></circle>
                      <circle cx="8" cy="20" r="2" fill="#0b2b33"></circle>
                      <circle cx="14" cy="14" r="2" fill="#0b2b33"></circle>
                    </g>
                  </g>
                </g>
              </svg>
              <div class="feature-detail">
                <h3>All phones</h3>
                <span>locate any phone or smartphone </span>
              </div>
            </li>
            <li class="feature-item">
              <svg
                class="feature-img"
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
              >
                <g fill="none" fill-rule="evenodd">
                  <path
                    fill="#5bbcc1"
                    stroke="#FFF"
                    stroke-width="4"
                    d="M16 64h9.56l.465.269A27.86 27.86 0 0 0 40 68c15.464 0 28-12.536 28-28S55.464 12 40 12 12 24.536 12 40a27.86 27.86 0 0 0 3.732 13.976l.268.464V64z"
                  ></path>
                  <g transform="rotate(45 -9.877 60.66)">
                    <circle cx="15.5" cy="15.5" r="2.5" fill="#0B2B33"></circle>
                    <path
                      stroke="#0B2B33"
                      stroke-linecap="round"
                      stroke-width="3"
                      d="M17.5 7C11.701 7 7 11.701 7 17.5M17.5 0C7.835 0 0 7.835 0 17.5"
                    ></path>
                  </g>
                </g>
              </svg>
              <div class="feature-detail">
                <h3>All operators</h3>
                <span>Works with all mobile operator networks. </span>
              </div>
            </li>
            <li class="feature-item">
              <svg
                class="feature-img"
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
              >
                <path
                  fill="#5bbcc1"
                  fill-rule="evenodd"
                  stroke="#FFF"
                  stroke-linejoin="round"
                  stroke-width="4"
                  d="M40 50.83L34.828 56H24c-5.523 0-10-4.477-10-10V34c0-5.523 4.477-10 10-10h32c5.523 0 10 4.477 10 10v12c0 5.523-4.477 10-10 10H45.172L40 50.83zM51.5 34c-3.666 0-6.5 2.334-6.5 5s2.834 5 6.5 5 6.5-2.334 6.5-5-2.834-5-6.5-5zm-23 0c-3.666 0-6.5 2.334-6.5 5s2.834 5 6.5 5 6.5-2.334 6.5-5-2.834-5-6.5-5z"
                ></path>
              </svg>
              <div class="feature-detail">
                <h3>100% Anonymous</h3>
                <span
                  >Your information is not <br />
                  shared. We will only use <br />
                  it to communicate with you.
                </span>
              </div>
            </li>
            <li class="feature-item">
              <svg
                class="feature-img"
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
              >
                <g fill="none" fill-rule="evenodd">
                  <path
                    fill="#5bbcc1"
                    stroke="#FFF"
                    stroke-linejoin="round"
                    stroke-width="4"
                    d="M28 62c-5.523 0-10-4.477-10-10V28c0-5.523 4.477-10 10-10h24c5.523 0 10 4.477 10 10v24c0 5.523-4.477 10-10 10H28z"
                  ></path>
                  <g transform="rotate(45 -9.006 61.006)">
                    <circle
                      cx="11.5"
                      cy="11.5"
                      r="11.5"
                      stroke="#0B2B33"
                      stroke-linecap="round"
                      stroke-width="3"
                    ></circle>
                    <path fill="#0B2B33" d="M10 0h3v22h-3z"></path>
                  </g>
                </g>
              </svg>
              <div class="feature-detail">
                <h3>No application</h3>
                <span
                  >Geolocation is done directly online. <br />
                  No need to install an application!</span
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="planet-container">
          <div class="pins d-none d-sm-none d-md-block">
            <img
              src="@/assets/geolocalice.png"
              style="
                top: -7px;
                left: 45%;
                width: 43px;
                height: 40px;
                position: absolute;
              "
            />
            <img
              src="@/assets/geolocalice.png"
              style="
                top: 33px;
                left: 17%;
                width: 43px;
                height: 40px;
                position: absolute;
              "
            />
            <img
              src="@/assets/geolocalice.png"
              style="
                top: 54px;
                left: 75%;
                width: 43px;
                height: 40px;
                position: absolute;
              "
            />
            <img
              src="@/assets/geolocalice.png"
              style="
                top: 82px;
                left: 33%;
                width: 43px;
                height: 40px;
                position: absolute;
              "
            />
            <img
              src="@/assets/geolocalice.png"
              style="
                top: 256px;
                left: 17%;
                width: 43px;
                height: 40px;
                position: absolute;
              "
            />
            <img
              src="@/assets/geolocalice.png"
              style="
                top: 220px;
                left: 73%;
                width: 43px;
                height: 40px;
                position: absolute;
              "
            />
            <img
              src="@/assets/geolocalice.png"
              style="
                top: 375px;
                left: 30%;
                width: 43px;
                height: 40px;
                position: absolute;
              "
            />
          </div>

          <picture class="d-none d-sm-none d-md-block">
            <img
              src="@/assets/earth.png"
              class="lazyload planet"
              loading="lazy"
              alt="landing_preview"
            />
          </picture>
        </div>
      </div>
    </section>
    <section id="features-2">
      <div class="container">
        <h2>locate any type of phone thanks to Geoloctel!</h2>
        <span
          ><i class="fa fa-long-arrow-right" style="color: #0b2b33"></i
          >Geoloctel works on all mobile devices.</span
        >
        <span
          ><i class="fa fa-long-arrow-right" style="color: #0b2b33"></i>It is
          possible to track any mobile phone number in the world..</span
        >
        <span
          ><i class="fa fa-long-arrow-right" style="color: #0b2b33"></i>send
          messages to any recipient.</span
        >
        <span
          ><i class="fa fa-long-arrow-right" style="color: #0b2b33"></i>Get the
          exact location displayed on a map.</span
        >
        <span
          ><i class="fa fa-long-arrow-right" style="color: #0b2b33"></i>Send
          unlimited geolocation requests to a phone.</span
        >
      </div>
    </section>
    <section id="working">
      <div class="container">
        <h2>How does it work?</h2>
        <div class="row">
          <div
            class="col-lg-4 col-sm-12 d-flex align-items-start justify-content-between"
          >
            <svg
              class="step-img"
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="120"
              viewBox="-11 20 100 40"
            >
              <g fill="none" fill-rule="evenodd">
                <rect
                  width="29"
                  height="4"
                  x="3"
                  y="27"
                  fill="#0B2B33"
                  rx="2"
                ></rect>
                <rect width="8" height="4" y="43" fill="#0B2B33" rx="2"></rect>
                <rect
                  width="29"
                  height="4"
                  x="11"
                  y="43"
                  fill="#0B2B33"
                  rx="2"
                ></rect>
                <rect
                  width="9"
                  height="4"
                  x="5"
                  y="35"
                  fill="#0B2B33"
                  rx="2"
                ></rect>
                <rect
                  width="19"
                  height="4"
                  x="16"
                  y="35"
                  fill="#0B2B33"
                  rx="2"
                ></rect>
                <path
                  fill="#5bbcc1"
                  stroke="#0B2B33"
                  stroke-width="4"
                  d="M68 19H36a8 8 0 0 0-8 8v21a8 8 0 0 0 8 8h2.143v7.91a1 1 0 0 0 1.57.822L52.294 56H68a8 8 0 0 0 8-8V27a8 8 0 0 0-8-8z"
                ></path>
                <path
                  fill="#0B2B33"
                  fill-rule="nonzero"
                  d="M48.752 46.35c-.384-.383-.575-.858-.575-1.425 0-.567.195-1.042.587-1.425a1.962 1.962 0 0 1 1.425-.575c.558 0 1.033.192 1.425.575.392.383.588.858.588 1.425 0 .567-.196 1.042-.588 1.425a1.962 1.962 0 0 1-1.425.575 2.004 2.004 0 0 1-1.437-.575zm-2.75-13.075c-.034-1.55.462-2.817 1.487-3.8C48.514 28.492 49.906 28 51.664 28s3.17.475 4.238 1.425c1.066.95 1.6 2.28 1.6 3.988 0 1.708-.484 2.945-1.45 3.712-.967.767-2.4 1.167-4.3 1.2v2.725h-3.3V36h1.425c1.433 0 2.495-.183 3.187-.55.692-.367 1.038-1.083 1.038-2.15 0-.733-.213-1.317-.638-1.75-.425-.433-1.008-.65-1.75-.65s-1.325.212-1.75.638c-.425.425-.637 1.004-.637 1.737h-3.325z"
                ></path>
              </g>
            </svg>
            <div class="d-flex p-3" style="flex-direction: column">
              <h3 class="fs-5">Send a request by sms</h3>
              <span style="text-align: justify"
                >Enter the smartphone number you want to search for.
              </span>
            </div>
          </div>
          <div
            class="col-lg-4 col-sm-12 d-flex align-items-start justify-content-between"
          >
            <svg
              class="step-img"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="200"
              height="120"
              viewBox="-20 20 100 40"
            >
              <defs>
                <path id="a" d="M0 0h1440v510H0z"></path>
              </defs>
              <g fill="none" fill-rule="evenodd">
                <path fill="#FFF" d="M-570-2586H870V745H-570z"></path>
                <use
                  fill="#f7f8f6"
                  transform="matrix(1 0 0 -1 -570 300)"
                  xlink:href="#a"
                ></use>
                <g transform="translate(19 5)">
                  <rect
                    width="38.6"
                    height="67"
                    x="2.2"
                    y="2"
                    fill="#5bbcc1"
                    stroke="#0B2B33"
                    stroke-width="4"
                    rx="10"
                  ></rect>
                  <path
                    fill="#0B2B33"
                    d="M16.5 28a7.363 7.363 0 0 1 7.306 6.45l.913 7.302A2 2 0 0 1 22.734 44H10.266a2 2 0 0 1-1.985-2.248l.913-7.302A7.363 7.363 0 0 1 16.5 28z"
                  ></path>
                  <path
                    stroke="#0B2B33"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    d="M22 25a6 6 0 0 1 6 6M22 19c6.627 0 12 5.373 12 12"
                  ></path>
                  <path
                    fill="#0B2B33"
                    d="M14 46h5v.5a2.5 2.5 0 1 1-5 0V46z"
                  ></path>
                  <rect
                    width="11"
                    height="6"
                    x="16"
                    y="2"
                    fill="#0B2B33"
                    rx="3"
                  ></rect>
                </g>
              </g>
            </svg>
            <div class="d-flex p-3" style="flex-direction: column">
              <h3 class="fs-5 text-justify">The valid recipient</h3>
              <span style="text-align: justify"
                >Recipient receives a text message to agree to be located</span
              >
            </div>
          </div>
          <div
            class="col-lg-4 col-sm-12 d-flex align-items-start justify-content-between"
          >
            <svg
              class="step-img"
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="120"
              viewBox="-11 20 100 40"
            >
              <g fill="none" fill-rule="evenodd">
                <rect
                  width="66"
                  height="66"
                  x="7"
                  y="7"
                  fill="#5bbcc1"
                  stroke="#0B2B33"
                  stroke-width="4"
                  rx="20"
                ></rect>
                <rect
                  width="2"
                  height="62"
                  x="55"
                  y="9"
                  fill="#238899"
                  rx="1"
                ></rect>
                <rect
                  width="62"
                  height="2"
                  x="9"
                  y="22"
                  fill="#238899"
                  rx="1"
                ></rect>
                <rect
                  width="62"
                  height="2"
                  x="9"
                  y="39"
                  fill="#238899"
                  rx="1"
                ></rect>
                <rect
                  width="62"
                  height="2"
                  x="9"
                  y="55"
                  fill="#238899"
                  rx="1"
                ></rect>
                <rect
                  width="2"
                  height="62"
                  x="39"
                  y="9"
                  fill="#238899"
                  rx="1"
                ></rect>
                <rect
                  width="2"
                  height="62"
                  x="23"
                  y="9"
                  fill="#238899"
                  rx="1"
                ></rect>
                <path d="M20 20h40v40H20z"></path>
                <path
                  fill="#0B2B33"
                  d="M40.5 33.84c-3.68 0-6.66 2.98-6.66 6.66s2.98 6.66 6.66 6.66 6.66-2.98 6.66-6.66-2.98-6.66-6.66-6.66zm14.8 5.18c-.538-7.215-6.105-12.782-13.32-13.32v-2.22c.202-.723-.555-1.48-1.48-1.48-.925 0-1.682.757-1.48 1.48v2.22c-7.215.538-12.782 6.105-13.32 13.32h-2.22c-.723-.202-1.48.555-1.48 1.48 0 .925.757 1.682 1.48 1.48h2.22c.538 7.215 6.105 12.782 13.32 13.32v2.22c-.202.723.555 1.48 1.48 1.48.925 0 1.682-.757 1.48-1.48V55.3c7.215-.538 12.782-6.105 13.32-13.32h2.22c.723.202 1.48-.555 1.48-1.48 0-.925-.757-1.682-1.48-1.48H55.3zM40.5 52.34A11.831 11.831 0 0 1 28.66 40.5c0-6.546 5.294-11.84 11.84-11.84s11.84 5.294 11.84 11.84-5.294 11.84-11.84 11.84z"
                ></path>
              </g>
            </svg>
            <div class="d-flex p-3" style="flex-direction: column">
              <h3 class="fs-5">Your recipient appears on the card</h3>
              <span style="text-align: justify"
                >A notification notifies you when your recipient is
                located.</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import gForm from "@/components/gForm1.vue";

export default {
  name: "HomeView",
  components: {
    gForm,
  },

  methods: {
    ActiveLoading(status) {
      this.$emit("loading", status);
    },
  },
  setup() {
    window.scroll({
      top: 0,

      behavior: "smooth",
    });
  },
};
</script>
<style lang="scss">
#home {
  .container {
    @media screen and(max-width:990px) {
      max-width: 540px;
    }
  }
  #landing {
    width: 100%;
    height: 790px;
    background-image: url("../assets/banner.png");
    background-size: cover;
    background-position: bottom;
    .form-container {
      padding-top: 20px;
      -webkit-transition: padding-top 0.25s;
      transition: padding-top 0.25s;
      @media (max-width: 990px) {
        width: 100%;
        .localize-form {
          width: 100%;
        }
        label {
          font-size: 14px;
        }
        .input-wrapper {
          max-width: 100%;
        }
      }
    }
    h2 {
      font-size: 45px;
      color: #fff;
      line-height: 66px;
      font-family: Poppins Bold, sans-serif;
      text-align: left;
      @media (max-width: 990px) {
        font-size: 32px;
      }
    }
    .container {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding-top: 130px;
      @media screen and(max-width:990px) {
        max-width: 600px;
      }
      @media screen and(max-width:500px) {
        max-width: 300px;
        padding-top: 70px;
      }
    }
    .subtitle {
      font-size: 20px;
      color: #fff;
      line-height: 30px;
    }
    .input-wrapper {
      display: -webkit-inline-box;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 4px 20px;
      height: 60px;
      width: 100%;
      max-width: 512px;
      position: relative;
      text-align: left;
      border-radius: 50px;
      border: 5px solid #fff;
      background: #fff;
      box-shadow: 0 9px 24px 0 rgb(0 0 0 / 12%);
      input {
        width: 100%;
        height: 100%;
        border: 0;
        outline: none;
        background: none;
        font-family: Poppins Regular, sans-serif;
        font-size: 16px;
        overflow: visible;
      }
    }
    .email-form {
      .font-awesome-position {
        margin-right: 16px;
        margin-top: -2px;
        font-size: 18px;
        margin-left: 5px;
        color: #525252;
      }
      .btn {
        height: 60px;
        background-color: #fff;
        border-radius: 50px;
        color: #0b2b33;
      }
    }
    .phone-container {
      position: relative;
      top: 100px;
      .card {
        z-index: 1;
        width: 288px;
        margin-left: 0;
        position: absolute;
        top: 50%;
        right: 158px;
        margin-top: -100px;
        color: black;
        .title {
          border-bottom: 1px solid #cecece;
          padding: 15px 20px;
          font-size: 18px;
          i {
            color: #e9381b;
            margin-right: 10px;
            font-size: 20px;
          }
        }
        .localise-detail {
          padding: 20px;
        }
        .address,
        .date {
          font-size: 14px;
          color: #525252;
          font-family: Poppins Regular, sans-serif;
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .phone {
      width: 380px;
      position: relative;
      bottom: 100px;
      margin-right: -23px;
    }

    @media screen and(max-width:300px) {
      height: 840px;
    }
  }
  #features {
    background-image: url("../assets/motif-ligth.png");
    background-size: cover;
    background-position: 0 -10px;
    height: auto;
    .container {
      padding-top: 250px;
    }
    .features-list {
      .feature-item {
        margin-bottom: 56px;
        display: inline-block;

        text-align: left;
      }
      .feature-img {
        background: #0b2b33;
        width: 100px;
        height: 100px;
        display: block;
        padding: 10px;
        float: left;
        border-radius: 100px;
      }
      .feature-detail {
        padding-left: 120px;
        margin-top: 10px;
      }
    }
    .planet {
      width: 502px;
      float: right;
      position: relative;
      bottom: 40px;
    }
    .planet-container {
      position: relative;
      .pins {
        position: absolute;
        bottom: 40px;
        width: 100%;
        height: 100%;
        img {
          z-index: 1;
          position: absolute;
          &.animate1 {
            -webkit-animation: bounce 0.35s ease infinite alternate;
            animation: bounce 0.35s ease infinite alternate;
          }
        }
      }
    }
    @media screen and(max-width:990px) {
      .container {
        padding-top: 100px;
      }
      .planet-container {
        display: none;
      }
      .container-features {
        width: 100%;
      }
      .feature-item {
        .feature-img {
          float: none;
        }
        .feature-detail {
          padding-left: 0;
          h3 {
            font-size: 20px;
          }
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
  #features-2 {
    text-align: left;
    .container {
      padding: 90px 0;
    }
    h2 {
      font-size: 34px;
      color: #000;
      margin-bottom: 50px;
    }
    span {
      color: #4f4f4f;
      font-family: Poppins Regular, sans-serif;
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      i {
        margin-right: 20px;
        color: #e9381b;
      }
    }
    @media screen and(max-width:990px) {
      h2 {
        font-size: 20px;
      }
      span {
        padding: 0 20px;
      }
    }
  }
  #working {
    text-align: left;
    background-color: #f7f8f6;
    .container {
      padding: 90px 0;
    }
    h2 {
      font-size: 34px;
      text-align: center;
      color: #000;
      margin-bottom: 50px;
    }
    .steps-list {
      .step-item {
        .step-img {
          float: left;
          display: inline-block;
        }
        .step-detail {
          padding-left: 100px;
          h3 {
            font-size: 17px;
            color: #000;
          }
          span {
            color: #4f4f4f;
            font-family: Poppins Regular, sans-serif;
          }
        }
      }
    }
    @media screen and(max-width:990px) {
      h2 {
        font-size: 24px;
      }
      .steps-list {
        padding: 0 20px;
        .step-item {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #ff0000;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 50%;
    top: 20%;
    margin: -20px 0 0 -20px;
    -webkit-animation-name: bounce;
    -moz-animation-name: bounce;
    -o-animation-name: bounce;
    -ms-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    -ms-animation-duration: 1s;
    animation-duration: 1s;
  }
  .pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 -22px;
    background: #2f2f2f;
    position: absolute;
    border-radius: 50%;
  }
  .pulse {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 20%;
    margin: 11px 0px 0px -12px;
    -webkit-transform: rotateX(55deg);
    -moz-transform: rotateX(55deg);
    -o-transform: rotateX(55deg);
    -ms-transform: rotateX(55deg);
    transform: rotateX(55deg);
    z-index: 1000;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -26px;
    -webkit-animation: pulsate 1s ease-out;
    -moz-animation: pulsate 1s ease-out;
    -o-animation: pulsate 1s ease-out;
    -ms-animation: pulsate 1s ease-out;
    animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-box-shadow: 0 0 1px 2px #89849b;
    box-shadow: 0 0 1px 2px #89849b;
    -webkit-animation-delay: 1.1s;
    -moz-animation-delay: 1.1s;
    -o-animation-delay: 1.1s;
    -ms-animation-delay: 1.1s;
    animation-delay: 1.1s;
  }
  @-moz-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-o-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      -moz-transform: scale(0.1, 0.1);
      -o-transform: scale(0.1, 0.1);
      -ms-transform: scale(0.1, 0.1);
      transform: scale(0.1, 0.1);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    50% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      -moz-transform: scale(1.2, 1.2);
      -o-transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
  }
  @-moz-keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
  @-webkit-keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
  @-o-keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
  @keyframes bounce {
    0% {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -webkit-transform: translateY(-2000px) rotate(-45deg);
      -moz-transform: translateY(-2000px) rotate(-45deg);
      -o-transform: translateY(-2000px) rotate(-45deg);
      -ms-transform: translateY(-2000px) rotate(-45deg);
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(30px) rotate(-45deg);
      -moz-transform: translateY(30px) rotate(-45deg);
      -o-transform: translateY(30px) rotate(-45deg);
      -ms-transform: translateY(30px) rotate(-45deg);
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
      -moz-transform: translateY(-10px) rotate(-45deg);
      -o-transform: translateY(-10px) rotate(-45deg);
      -ms-transform: translateY(-10px) rotate(-45deg);
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
      -moz-transform: translateY(0) rotate(-45deg);
      -o-transform: translateY(0) rotate(-45deg);
      -ms-transform: translateY(0) rotate(-45deg);
      transform: translateY(0) rotate(-45deg);
    }
  }
}
</style>
