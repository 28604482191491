import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Notifications from "@kyvg/vue3-notification";

import { Tooltip } from "bootstrap";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/styles.scss";
new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

//import vuebraintree from "vue-braintree";

createApp(App)
  .use(store)
  .use(router)
  .use(VueTelInput)
  .use(Notifications)
  .mount("#app");
