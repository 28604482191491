import axios from "axios";
import Cookies from "js-cookie";
/* baseURL: "http://localhost:5001/geomyclon/us-central1/api/v1", */

let configApi = {
  baseURL: "https://us-central1-geomyclon.cloudfunctions.net/api/v1",
  //baseURL: "http://localhost:5001/geomyclon/us-central1/api/v1",
};
let configFirebase = {
  baseURL:
    process.env.VUE_APP_FIREBASE_URL +
    "token?key=" +
    process.env.VUE_APP_FIREBASE_API_KEY,
};

const axiosApi = axios.create(configApi);
const axiosFirebase = axios.create(configFirebase);

axiosApi.interceptors.request.use(
  function (config) {
    if (Cookies.get("auth")) {
      let cookies = JSON.parse(Cookies.get("auth"));
      config.headers.Authorization = "Bearer " + cookies.idToken;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export { axiosApi, axiosFirebase };
