import { createStore } from "vuex";
import { actions } from "./actions";

import Auth from "./modules/auth";
import User from "./modules/user";

export default createStore({
  state: {
    plans: [
      {
        name: "Basic",
        credits: 10,
        price: 15.95,
        id: 1,
      },
      {
        name: "Premium",
        credits: 30,
        price: 30,
        id: 3,
      },
      {
        name: "Standar",
        credits: 20,
        price: 21.95,
        id: 2,
      },
      
    ],
  },
  getters: {
    getPlans(state) {
      return state.plans;
    },
  },
  mutations: {},
  actions,
  modules: { Auth, User },
});
